import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Tabs from './Tabs';
import Library from './Library';
import InboxList from './InboxList';
import ThoughtDetail from './ThoughtDetail';
import ComposePage from './ComposePage';
import FieldEditPage from './FieldEditPage';
import FloatingActionButton from './FloatingActionButton';
import ThoughtsList from './ThoughtsList';
import AddToCollectionPage from './AddToCollectionPage';
import { getCollectionThoughts } from '../services/collectionService';

const AppContent = ({ activeTab, setActiveTab, thoughts, saveThought, deleteThought, updateThoughtLocally, themes, motifs, tags, isDataLoading }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Check if on detail page (to remove padding when header is hidden)
  const isDetailPage = location.pathname.includes('/thought/') || 
                      location.pathname.includes('/edit/') ||
                      location.pathname.includes('/edit-field/') ||
                      location.pathname.includes('/compose') ||
                      location.pathname.includes('/collection/');
  
  // Check screen size for responsive behavior
  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Filter thoughts for the inbox view (only thoughts with no theme, motif, or tags)
  const inboxThoughts = thoughts.filter(thought => 
    (!thought.theme || thought.theme === '') && 
    (!thought.motif || thought.motif === '') && 
    (!thought.tags || thought.tags.length === 0)
  );

  // All thoughts for the regular list view
  const allThoughts = thoughts;

  // Handle deleting a thought
  const handleDeleteThought = (thoughtId) => {
    // Use the deleteThought function passed from App.js
    if (deleteThought && thoughtId) {
      deleteThought(thoughtId);
    }
  };

  // Navigation handler for going back to Library
  const handleBackToLibrary = () => {
    setActiveTab("library");
    navigate('/');
  };

  return (
    <>
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: isDetailPage ? '0' : '60px', // Only add padding when header is shown
        marginTop: 0,
      }}>
        
        {/* Main content area - dynamically changes based on active tab */}
        <div style={{
          flex: 1,
          overflow: 'auto',
          padding: isMobile ? '8px' : '16px 24px',
          paddingBottom: '80px', // Extra padding for FAB
        }}>
          <Routes>
            <Route path="/" element={
              <>
                {/* Tabs for all devices */}
                <Tabs
                  activeTab={activeTab}
                  onTabChange={setActiveTab}
                  inboxCount={inboxThoughts.length}
                />

                {/* Content based on active tab */}
                {activeTab === "inbox" ? (
                  <InboxList 
                    thoughts={inboxThoughts} 
                    emptyMessage="Enjoy the mental pause"
                    onDeleteThought={handleDeleteThought}
                  />
                ) : (
                  <Library 
                    navigateToCollection={(collectionId, collectionName, isDefault) => 
                      navigate(`/collection/${collectionId}`, { 
                        state: { 
                          collectionName,
                          isDefault 
                        } 
                      })
                    }
                  />
                )}
              </>
            } />
            <Route path="/thought/:id" element={<ThoughtDetail thoughts={thoughts} onSave={saveThought} isDataLoading={isDataLoading} updateThoughtLocally={updateThoughtLocally} />} />
            <Route path="/compose" element={<ComposePage onSave={saveThought} />} />
            <Route path="/edit/:id" element={<ComposePage 
              onSave={saveThought} 
              isEditing={true} 
              thoughts={thoughts} 
            />} />
            <Route path="/edit-field/:id/:fieldName" element={
              <FieldEditPage
                onSave={saveThought}
                thoughts={thoughts}
                themes={themes}
                motifs={motifs}
                tags={tags}
                isDataLoading={isDataLoading}
              />
            } />
            <Route path="/collection/:collectionId" element={
              <CollectionView 
                thoughts={allThoughts}
                onDeleteThought={handleDeleteThought}
                onBackClick={handleBackToLibrary}
              />
            } />
            <Route path="/collection/:collectionId/add" element={
              <AddToCollectionPage 
                thoughts={allThoughts}
              />
            } />
          </Routes>
          <FloatingActionButton />
        </div>
      </div>
    </>
  );
};

// Collection View Component - Renders a collection without tabs
const CollectionView = ({ thoughts, onDeleteThought, onBackClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  
  // Extract collection ID from the URL
  const collectionId = location.pathname.split('/collection/')[1];
  const collectionName = state?.collectionName || "Collection";
  const isDefault = state?.isDefault || false;
  
  const [collectionThoughts, setCollectionThoughts] = useState([]);
  const [, setLoading] = useState(true);
  
  // Get collection thoughts
  useEffect(() => {
    const fetchCollectionThoughts = async () => {
      setLoading(true);
      try {
        // If this is the default collection, just use all thoughts
        if (isDefault) {
          setCollectionThoughts(thoughts);
          setLoading(false);
          return;
        }
        
        // Otherwise fetch the collection thoughts
        const fetchedThoughts = await getCollectionThoughts(collectionId);
        setCollectionThoughts(fetchedThoughts);
      } catch (error) {
        console.error('Error fetching collection thoughts:', error);
        setCollectionThoughts([]);
      } finally {
        setLoading(false);
      }
    };
    
    fetchCollectionThoughts();
  }, [collectionId, isDefault, thoughts]);
  
  // Handle back with animation
  const handleBack = () => {
    // Find the container element and apply the animation
    const container = document.querySelector('.collection-view-container');
    if (container) {
      // Apply a simple fade out animation (opacity only, no movement)
      container.style.animation = 'simpleFadeOut 0.2s cubic-bezier(0.2, 0, 0, 1) forwards';
      
      // Wait for animation to complete before navigating
      setTimeout(() => {
        onBackClick();
      }, 200); // Reduced time to match the shorter animation
    } else {
      // If container not found, navigate immediately
      onBackClick();
    }
  };
  
  // Handle adding thoughts to collection
  const handleAddToCollection = () => {
    navigate(`/collection/${collectionId}/add`);
  };
  
  return (
    <>
      <div className="collection-view-container" style={{ width: '100%' }}>
        {/* Add the animation keyframes */}
        <style>
          {`
            @keyframes simpleFadeOut {
              from { opacity: 1; }
              to { opacity: 0; }
            }
          `}
        </style>
        
        <ThoughtsList
          thoughts={collectionThoughts}
          title={collectionName}
          onDeleteThought={onDeleteThought}
          onBackClick={handleBack}
          showBackButton={true}
          onAddClick={!isDefault ? handleAddToCollection : undefined}
        />
      </div>
    </>
  );
};

export default AppContent; 