import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AutocompleteInput from './AutocompleteInput';
import TagsInput from './TagsInput';
import useAutocomplete from '../hooks/useAutocomplete';
import useTags from '../hooks/useTags';
import { colors } from '../styles/styles';
import { useSwipeable } from 'react-swipeable';

const FieldEditPage = ({ thoughts, onSave, themes = [], motifs = [], tags = [], isDataLoading = false }) => {
    const { id, fieldName } = useParams();
    const navigate = useNavigate();
    
    // Find the thought
    const [thought, setThought] = useState(null);
    
    // Add loading state to prevent flashing
    const [isLocalLoading, setIsLocalLoading] = useState(true);
    const isLoading = isDataLoading || isLocalLoading;
    
    // Handle navigation to detail page
    const handleCancel = () => {
        const container = document.querySelector('.field-edit-container');
        if (container) {
            container.style.animation = 'fadeOut 0.2s cubic-bezier(0.2, 0, 0, 1) forwards';
            setTimeout(() => navigate(`/thought/${id}`), 180);
        } else {
            navigate(`/thought/${id}`);
        }
    };
    
    // Handle navigation to root page
    const handleBack = () => {
        const container = document.querySelector('.field-edit-container');
        if (container) {
            container.style.animation = 'fadeOut 0.2s cubic-bezier(0.2, 0, 0, 1) forwards';
            setTimeout(() => navigate('/', { replace: true }), 180);
        } else {
            navigate('/', { replace: true });
        }
    };
    
    // Swipe handlers for going back to root
    const swipeHandlers = useSwipeable({
        onSwipedRight: (eventData) => {
            if (eventData.initial[0] < 50) handleBack();
        },
        trackMouse: false,
        delta: 50,
        preventDefaultTouchmoveEvent: false,
    });
    
    // Load the thought on component mount
    useEffect(() => {
        const foundThought = thoughts.find(t => t.id === id);
        setThought(foundThought);
        
        // Use a small timeout to ensure smooth transition
        setTimeout(() => {
            setIsLocalLoading(false);
        }, 100);
        
        // Redirect if thought not found after loading completes
        if (!foundThought && !isLoading) {
            navigate('/');
        }
    }, [id, thoughts, navigate, isLoading]);
    
    // State for the current field value
    const [importanceValue, setImportanceValue] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    
    // State for comments field
    const [commentsValue, setCommentsValue] = useState('');
    
    // Initialize with available options from props
    const themeAutocomplete = useAutocomplete(themes);
    const motifAutocomplete = useAutocomplete(motifs);
    const tagsManager = useTags(tags);
    
    // Initialize values when thought is loaded
    useEffect(() => {
        if (thought) {
            // Set field-specific values
            if (fieldName === 'theme') {
                if (thought.theme) {
                    themeAutocomplete.selectOption(thought.theme);
                } else {
                    themeAutocomplete.resetInput();
                }
            } else if (fieldName === 'motif') {
                if (thought.motif) {
                    motifAutocomplete.selectOption(thought.motif);
                } else {
                    motifAutocomplete.resetInput();
                }
            } else if (fieldName === 'importance') {
                setImportanceValue(thought.importance !== undefined ? thought.importance : null);
            } else if (fieldName === 'tags') {
                if (thought.tags && thought.tags.length > 0) {
                    // Use setTags instead of resetTags + forEach
                    tagsManager.setTags(thought.tags);
                } else {
                    tagsManager.resetTags();
                }
            } else if (fieldName === 'comments') {
                setCommentsValue(thought.comments || '');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thought, fieldName]);

    // Update available options when they change
    useEffect(() => {
        themeAutocomplete.updateOptions(themes);
        motifAutocomplete.updateOptions(motifs);
        tagsManager.updateAvailableTags(tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themes, motifs, tags]);

    if (isLoading) {
        return (
            <div style={{ 
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: '16px',
                backgroundColor: colors.background,
                zIndex: 10,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxHeight: '100vh',
                animation: 'fadeIn 0.3s cubic-bezier(0.2, 0, 0, 1)',
                willChange: 'opacity',
            }}>
                {/* Empty loading state without text */}
            </div>
        );
    }
    
    // Show error if thought wasn't found after loading
    if (!thought) {
        return (
            <div style={{ 
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: '16px',
                backgroundColor: colors.background,
                zIndex: 10,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                maxHeight: '100vh',
                animation: 'fadeIn 0.3s cubic-bezier(0.2, 0, 0, 1)',
                willChange: 'opacity',
            }}>
                <p style={{ color: colors.textPrimary, marginBottom: '16px' }}>Item not found</p>
                <button 
                    onClick={() => navigate('/')} 
                    style={{
                        backgroundColor: colors.accent.blue,
                        color: 'white',
                        border: 'none',
                        borderRadius: '20px',
                        padding: '8px 16px',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                >
                    Back to Library
                </button>
            </div>
        );
    }

    const handleSave = async () => {
        if (isSaving) return;
        setIsSaving(true);
        
        try {
            const updatedThought = { ...thought };
            
            // Update only the specific field
            switch (fieldName) {
                case 'theme':
                    updatedThought.theme = themeAutocomplete.value;
                    break;
                case 'motif':
                    updatedThought.motif = motifAutocomplete.value;
                    break;
                case 'importance':
                    updatedThought.importance = importanceValue;
                    break;
                case 'tags':
                    updatedThought.tags = tagsManager.selectedTags;
                    break;
                case 'comments':
                    updatedThought.comments = commentsValue;
                    break;
                default:
                    break;
            }
            
            await onSave(updatedThought);
            navigate(`/thought/${id}`);
        } catch (error) {
            console.error('Error saving field:', error);
        } finally {
            setIsSaving(false);
        }
    };

    // Get field title for display
    const getFieldTitle = () => {
        switch (fieldName) {
            case 'theme':
                return 'Theme';
            case 'motif':
                return 'Motif';
            case 'importance':
                return 'Importance';
            case 'tags':
                return 'Tags';
            case 'comments':
                return 'Comments';
            default:
                return 'Edit Field';
        }
    };

    // Render the appropriate field editor based on fieldName
    const renderFieldEditor = () => {
        switch (fieldName) {
            case 'theme':
                return (
                    <AutocompleteInput
                        label="Theme"
                        value={themeAutocomplete.value}
                        onChange={themeAutocomplete.handleInputChange}
                        options={themeAutocomplete.filteredOptions}
                        onSelect={themeAutocomplete.selectOption}
                        onFocus={themeAutocomplete.showOptions}
                        onBlur={themeAutocomplete.hideOptions}
                        placeholder="Add a theme (e.g. Career, Relationships, Health)"
                        style={{ width: '100%', marginTop: '32px' }}
                    />
                );
            case 'motif':
                return (
                    <AutocompleteInput
                        label="Motif"
                        value={motifAutocomplete.value}
                        onChange={motifAutocomplete.handleInputChange}
                        options={motifAutocomplete.filteredOptions}
                        onSelect={motifAutocomplete.selectOption}
                        onFocus={motifAutocomplete.showOptions}
                        onBlur={motifAutocomplete.hideOptions}
                        placeholder="Add a recurring pattern or motif"
                        style={{ width: '100%', marginTop: '32px' }}
                    />
                );
            case 'tags':
                return (
                    <TagsInput
                        tagInput={tagsManager.tagInput}
                        selectedTags={tagsManager.selectedTags}
                        filteredTags={tagsManager.filteredTags}
                        showDropdown={tagsManager.showTagDropdown}
                        onInputChange={tagsManager.handleTagInputChange}
                        onKeyPress={tagsManager.handleTagKeyPress}
                        onFocus={() => tagsManager.setShowTagDropdown(true)}
                        onBlur={(e) => {
                            // Delay closing to check if click was on a dropdown item
                            // This allows clicking on dropdown items without closing
                            const currentTarget = e.currentTarget;
                            setTimeout(() => {
                                // Only close if focus is not within the input or dropdown
                                if (!currentTarget.contains(document.activeElement)) {
                                    tagsManager.setShowTagDropdown(false);
                                }
                            }, 300);
                        }}
                        onTagSelect={(tag) => {
                            tagsManager.addTag(tag);
                            // Keep dropdown open after selection
                            tagsManager.setShowTagDropdown(true);
                        }}
                        onTagRemove={tagsManager.removeTag}
                        style={{ width: '100%', marginTop: '32px' }}
                    />
                );
            case 'importance':
                return (
                    <div style={{ marginTop: '32px' }}>
                        <label style={{
                            display: 'block',
                            marginBottom: '16px',
                            fontWeight: '500',
                            fontSize: '16px',
                            color: '#697386',
                        }}>
                            Importance
                        </label>
                        
                        {/* Discrete slider for importance */}
                        <div style={{
                            width: '100%',
                            marginBottom: '24px'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                gap: '16px'
                            }}>
                                {/* "Not set" option to set importance to null */}
                                <button
                                    type="button"
                                    onClick={() => setImportanceValue(null)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '12px 16px',
                                        backgroundColor: importanceValue === null ? 'rgba(0, 122, 255, 0.1)' : '#F5F7FA',
                                        border: importanceValue === null ? '1px solid #007AFF' : '1px solid #E5E9EF',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        transition: 'all 150ms ease',
                                    }}
                                >
                                    <div style={{
                                        flex: 1,
                                        textAlign: 'left',
                                        fontSize: '16px',
                                        color: '#1A1F36',
                                        fontWeight: importanceValue === null ? '500' : '400',
                                    }}>
                                        Not set
                                    </div>
                                    {importanceValue === null && (
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 12L10 17L20 7" stroke="#007AFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    )}
                                </button>
                                
                                {/* Discrete slider options from 1-5 */}
                                {[5, 4, 3, 2, 1].map(level => (
                                    <button
                                        key={level}
                                        type="button"
                                        onClick={() => setImportanceValue(level)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '12px 16px',
                                            borderRadius: '8px',
                                            backgroundColor: importanceValue === level ? 'rgba(0, 122, 255, 0.1)' : '#F5F7FA',
                                            border: importanceValue === level ? '1px solid #007AFF' : '1px solid #E5E9EF',
                                            cursor: 'pointer',
                                            transition: 'all 150ms ease',
                                        }}
                                    >
                                        <div style={{
                                            width: '32px',
                                            height: '32px',
                                            borderRadius: '50%',
                                            backgroundColor: '#FFFFFF',
                                            color: '#1A1F36',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontWeight: '600',
                                            marginRight: '12px',
                                            border: '1px solid #E5E9EF'
                                        }}>
                                            {level}
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            textAlign: 'left',
                                            fontSize: '16px',
                                            color: '#1A1F36',
                                            fontWeight: importanceValue === level ? '500' : '400',
                                        }}>
                                            {level === 5 ? 'Very important' : 
                                             level === 4 ? 'Important' : 
                                             level === 3 ? 'Moderate' : 
                                             level === 2 ? 'Low importance' : 
                                             'Very low importance'}
                                        </div>
                                        {importanceValue === level && (
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 12L10 17L20 7" stroke="#007AFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        )}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            case 'comments':
                return (
                    <div style={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: colors.background,
                        zIndex: 20, // Higher than the container
                    }}>
                        {/* Header with title and save button */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '16px',
                            maxWidth: '800px',
                            margin: '0 auto',
                            width: '100%',
                        }}>
                            <button 
                                onClick={handleCancel}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke={colors.textPrimary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            
                            <h1 style={{
                                margin: 0,
                                fontSize: '18px',
                                fontWeight: '600',
                                color: colors.textPrimary,
                            }}>
                                Comments
                            </h1>
                            
                            <button
                                onClick={handleSave}
                                disabled={isSaving}
                                style={{
                                    backgroundColor: colors.accent.blue,
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '20px',
                                    padding: '8px 16px',
                                    cursor: isSaving ? 'default' : 'pointer',
                                    opacity: isSaving ? 0.7 : 1,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                }}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                        
                        {/* Container with max-width */}
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '800px',
                            margin: '0 auto',
                            width: '100%',
                            padding: '0 16px',
                            overflowY: 'auto',
                        }}>
                            {/* Original thought content display */}
                            {thought && (
                                <div style={{
                                    backgroundColor: colors.surfaceElevated,
                                    padding: '16px',
                                    margin: '16px 0',
                                    borderRadius: '8px',
                                }}>
                                    <div style={{
                                        fontSize: '15px',
                                        lineHeight: '1.5',
                                        color: colors.textPrimary,
                                        whiteSpace: 'pre-wrap',
                                    }}>
                                        {thought.thought || thought.content || ''}
                                    </div>
                                </div>
                            )}
                            
                            {/* Full-screen textarea */}
                            <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '0 0 16px 0',
                                marginBottom: '16px',
                            }}>
                                <textarea
                                    value={commentsValue}
                                    onChange={(e) => setCommentsValue(e.target.value)}
                                    placeholder="Add your comments here..."
                                    style={{
                                        flex: 1,
                                        border: 'none',
                                        padding: '16px',
                                        fontSize: '15px', // Matching the original thought font size
                                        lineHeight: '1.5',
                                        resize: 'none',
                                        outline: 'none',
                                        backgroundColor: colors.surfaceElevated,
                                        color: colors.textPrimary,
                                        borderRadius: '8px',
                                        minHeight: '250px',
                                        fontFamily: 'inherit',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                    autoFocus
                                />
                            </div>
                        </div>
                    </div>
                );
            default:
                return <div>Invalid field</div>;
        }
    };

    return (
        <div 
            {...swipeHandlers}
            className="field-edit-container"
            style={{ 
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: '16px',
                backgroundColor: colors.background,
                zIndex: 10,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxHeight: '100vh',
                animation: 'fadeIn 0.3s cubic-bezier(0.2, 0, 0, 1)',
                willChange: 'opacity',
            }}>
            {/* Header with back button and title */}
            <div style={{ 
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
                paddingLeft: '0px',
                maxWidth: '800px',
                margin: '0 auto 24px',
                width: '100%',
            }}>
                <button 
                    onClick={handleCancel}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        marginLeft: '0px',
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke={colors.textPrimary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                
                <h1 style={{ 
                    margin: 0,
                    fontSize: '18px',
                    fontWeight: '600',
                    flex: 1,
                    textAlign: 'center',
                    color: colors.textPrimary,
                    marginLeft: '0px',
                }}>
                    {getFieldTitle()}
                </h1>
                
                <button
                    onClick={handleSave}
                    disabled={isSaving}
                    style={{
                        backgroundColor: colors.accent.blue,
                        color: 'white',
                        border: 'none',
                        borderRadius: '20px',
                        padding: '8px 16px',
                        cursor: isSaving ? 'default' : 'pointer',
                        opacity: isSaving ? 0.7 : 1,
                        fontSize: '14px',
                        fontWeight: '500',
                        position: 'relative',
                        paddingLeft: isSaving ? '32px' : '16px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {isSaving && (
                        <span style={{
                            position: 'absolute',
                            left: '12px',
                            width: '14px',
                            height: '14px',
                            borderRadius: '50%',
                            border: '2px solid rgba(255,255,255,0.3)',
                            borderTopColor: '#fff',
                            animation: 'spin 1s linear infinite',
                        }} />
                    )}
                    {isSaving ? 'Saving...' : 'Save'}
                </button>
            </div>
            
            {/* Field editor */}
            <div style={{ 
                flex: 1,
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingBottom: '20px',
                maxWidth: '800px',
                margin: '0 auto',
                width: '100%',
            }}>
                {renderFieldEditor()}
                
                {/* Add animation for spinner */}
                <style>{`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}</style>
            </div>
        </div>
    );
};

export default FieldEditPage; 