import React, { useState } from 'react';
import { colors } from '../styles/styles';
import { createCollection } from '../services/collectionService';

/**
 * Modal for creating a new collection
 */
const CreateCollectionModal = ({ isVisible, onClose, onSuccess }) => {
    const [name, setName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    if (!isVisible) return null;
    
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!name.trim()) {
            return; // Don't submit with empty name
        }
        
        setIsSubmitting(true);
        
        try {
            await createCollection(name.trim(), ''); // Empty description for now
            setName('');
            
            if (onSuccess) {
                onSuccess();
            }
            
            if (onClose) {
                onClose();
            }
        } catch (error) {
            console.error('Error creating collection:', error);
            // Handle error (would add proper error handling in a production app)
        } finally {
            setIsSubmitting(false);
        }
    };
    
    // Handle modal close
    const handleClose = () => {
        if (!isSubmitting && onClose) {
            onClose();
        }
    };
    
    // Handle backdrop click
    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget && !isSubmitting) {
            onClose();
        }
    };
    
    return (
        <div 
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000,
            }}
            onClick={handleBackdropClick}
        >
            <div style={{
                backgroundColor: colors.surfaceElevated,
                borderRadius: '16px',
                padding: '20px',
                width: '90%',
                maxWidth: '360px',
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
            }}>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '16px' }}>
                        <label 
                            htmlFor="collection-name"
                            style={{
                                display: 'block',
                                marginBottom: '8px',
                                fontSize: '14px',
                                fontWeight: '500',
                                color: colors.textSecondary
                            }}
                        >
                            Collection Name
                        </label>
                        <input
                            id="collection-name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder=""
                            style={{
                                width: '100%',
                                padding: '12px 16px',
                                backgroundColor: colors.background,
                                border: 'none',
                                borderRadius: '8px',
                                fontSize: '16px',
                                color: colors.textPrimary,
                                outline: 'none',
                            }}
                            autoFocus
                        />
                    </div>
                    
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '8px'
                    }}>
                        <button
                            type="button"
                            onClick={handleClose}
                            disabled={isSubmitting}
                            style={{
                                backgroundColor: 'transparent',
                                color: colors.accent.blue,
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                cursor: isSubmitting ? 'not-allowed' : 'pointer',
                                opacity: isSubmitting ? 0.5 : 1,
                            }}
                        >
                            Cancel
                        </button>
                        
                        <button
                            type="submit"
                            disabled={!name.trim() || isSubmitting}
                            style={{
                                backgroundColor: colors.accent.blue,
                                color: colors.textPrimary,
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                cursor: (!name.trim() || isSubmitting) ? 'not-allowed' : 'pointer',
                                opacity: (!name.trim() || isSubmitting) ? 0.5 : 1,
                            }}
                        >
                            {isSubmitting ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateCollectionModal; 