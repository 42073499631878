import React, { useState, useEffect } from 'react';
import { colors } from '../styles/styles';
import CreateCollectionModal from './CreateCollectionModal';
import { getCollections, ensureDefaultCollection } from '../services/collectionService';

/**
 * Library component that displays a list of thought collections
 */
const Library = ({ navigateToCollection }) => {
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [authError, setAuthError] = useState(false);
    
    // Fetch collections from Firebase on component mount
    useEffect(() => {
        fetchCollections();
    }, []);
    
    // Function to fetch collections (can be called after creating a new collection)
    const fetchCollections = async () => {
        setLoading(true);
        setAuthError(false);
        
        try {
            // Ensure the default collection exists
            await ensureDefaultCollection();
            
            // Fetch all collections
            const fetchedCollections = await getCollections();
            setCollections(fetchedCollections);
        } catch (error) {
            console.error('Error fetching collections:', error);
            // Check if this is an authentication error
            if (error.message && error.message.includes('not authenticated')) {
                setAuthError(true);
            }
            // Handle the error appropriately - at minimum show an empty state
            setCollections([]);
        } finally {
            setLoading(false);
        }
    };

    // Handle showing the create collection modal
    const handleCreateClick = () => {
        setShowCreateModal(true);
    };
    
    // Handle collection creation success
    const handleCollectionCreated = () => {
        fetchCollections(); // Refresh the collections
    };

    // Handle clicking on a collection
    const handleCollectionClick = (collection) => {
        // Navigate to the collection view using the new route
        navigateToCollection(collection.id, collection.name, collection.isDefault);
    };

    // Sort collections to ensure "Your Thoughts" is always at the top
    const getSortedCollections = () => {
        if (!collections.length) return [];
        
        // Separate default collection and other collections
        const defaultCollection = collections.find(c => c.isDefault);
        const otherCollections = collections.filter(c => !c.isDefault);
        
        // Sort other collections by most recently updated
        const sortedCollections = otherCollections.sort((a, b) => {
            const dateA = a.updatedAt ? new Date(a.updatedAt.toDate()) : new Date(0);
            const dateB = b.updatedAt ? new Date(b.updatedAt.toDate()) : new Date(0);
            return dateB - dateA;
        });
        
        // Return with default collection at the top if it exists
        return defaultCollection ? [defaultCollection, ...sortedCollections] : sortedCollections;
    };

    // Add an authentication error state
    if (authError) {
        return (
            <div style={{ textAlign: 'center', padding: '40px 0', color: colors.textSecondary }}>
                <p>You need to be signed in to access collections.</p>
                <button
                    onClick={() => window.location.reload()} // Simple reload to trigger auth
                    style={{
                        backgroundColor: colors.accent.blue,
                        color: 'white',
                        border: 'none',
                        padding: '10px 16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        marginTop: '16px',
                        cursor: 'pointer'
                    }}
                >
                    Sign In
                </button>
            </div>
        );
    }

    // Get sorted collections
    const sortedCollections = getSortedCollections();

    return (
        <div style={{ width: '100%' }} className="smooth-scroll">
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '24px',
                padding: '0 16px',
                height: '38px' // Standardized header height
            }}>
                <h2 style={{
                    margin: 0,
                    fontSize: '22px',
                    fontWeight: '600',
                    color: colors.textPrimary,
                    lineHeight: '38px', // Match the container height
                }}>
                    Library
                </h2>
                <button
                    onClick={handleCreateClick}
                    style={{
                        background: 'none',
                        border: 'none',
                        padding: '4px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '32px',
                        height: '32px'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 5V19M5 12H19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="butt" strokeLinejoin="miter"/>
                    </svg>
                </button>
            </div>
            
            {/* Render collections or empty state */}
            {!loading && collections.length === 0 ? (
                <div style={{ textAlign: 'center', padding: '20px', color: colors.textSecondary }}>
                    No collections found. Create your first collection.
                </div>
            ) : !loading && (
                <div className="collections-feed" style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    width: '100%',
                }}>
                    {sortedCollections.map((collection, index) => (
                        <div 
                            key={collection.id}
                            style={{
                                padding: '16px',
                                cursor: 'pointer',
                                backgroundColor: colors.background,
                                borderBottom: index === sortedCollections.length - 1 ? 'none' : `1px solid ${colors.divider}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                animation: `fadeIn 0.3s ease forwards`,
                                opacity: 0,
                                transform: 'translateY(10px)',
                                willChange: 'transform, opacity',
                            }} 
                            onClick={() => handleCollectionClick(collection)}
                        >
                            <span style={{
                                fontSize: '15px',
                                lineHeight: '1.5',
                                color: colors.textPrimary,
                            }}>{collection.name}</span>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L15 12L9 6" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    ))}
                </div>
            )}
            
            {/* Create Collection Modal */}
            <CreateCollectionModal 
                isVisible={showCreateModal}
                onClose={() => setShowCreateModal(false)}
                onSuccess={handleCollectionCreated}
            />
        </div>
    );
};

export default Library; 